angular.module('deitz').controller('jobProfitReportController', [
    '$scope','$state','$rootScope','$http','apiUrl','Notification','commonFactory','$window',
    function(
        $scope,$state,$rootScope,$http,apiUrl,Notification,commonFactory,$window
    ){

        $scope.report = {
            startDate : moment().format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD'),
            billerIds : []
        };
        $scope.selectedFirms = [];
        $scope.selectedJobs = [];
        $scope.jobs = [];
        $scope.calculatedData = [];

        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};

        $scope.title = "Job Report ";
        $scope.isSalesPersonLogin = false;
        //console.log($rootScope);
        // make hide profit information check box disabled when sales persion login.
        if($rootScope.isSalesLogin){
            console.log($rootScope);
            $scope.hideProfit = true;
            $scope.isSalesPersonLogin = true;
        }

        commonFactory.get('/api/rates')
        .success(function(response) { 
            if (response.result && response.result.rates) {
                $scope.insuranceRates = response.result.rates;
            }
        }).error(function(err) {});
        //get business units
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
     
        $scope.selectedRateGroupIDs = [];
        commonFactory.get('/api/rateCardGroups')
            .success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
            }).error(function (err) {}); 
      
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });
       
        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };
        $scope.selectedJobTypeIDs = [];        
        $http.get(apiUrl + '/api/jobtypes').then(function(response) { 
            $scope.JobTypeList = response.data.result.jobtype;  
            $scope.JobTypeList.unshift({name:'All'});
        });
        //Getting Job list
        $scope.jobList = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });

        commonFactory.post('/api/resource-list-by-type',{rep_typ:'R'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.reporterList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });
        $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
            $scope.additionalinfo_list = response.data.result.data;  
        });
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'T'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.typistList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });
        
        //Getting all terms 
        if(!$scope.isSalesPersonLogin){
        $http.post(apiUrl + '/api/getAllterms').success(function(res){
            $scope.termsList = res.data;
        });
    }
    if($scope.isSalesPersonLogin){

        commonFactory.get('/api/attorneyListBySalesman')
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsAttrneyList = response.result;
            }
            $rootScope.showLoader(false);
        });
    }

        //Getting all sources from source table.
        if(!$scope.isSalesPersonLogin){
            $http.get(apiUrl + '/api/get_source').then(function(response) { 
                $scope.sourcesList=response.data.data;       
            }); 
        }

        //Getting all status of firm.
        $http.get(apiUrl + '/api/get-status-of-firm').success(function(response) { 
            $scope.statusList=response.result;       
        }); 

       $http.get(apiUrl + '/api/get-biller-list').success(function(response) {
            $scope.billerList=response.result
        });

        $scope.searchData = function(){

            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
            $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $scope.report.attorneyFirmIds = $scope.selectedFirms.map(function(a){
                return  a.id;
            });

            $scope.report.isSalesPersonLogin = $rootScope.isSalesLogin;
            
            $scope.title = "Job Report for Invoice : "+ moment($scope.report.startDate).format('MM/DD/YYYY') + " to " + moment($scope.report.endDate).format('MM/DD/YYYY');

            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
            $scope.report.endDate= moment($scope.report.endDate).format('YYYY-MM-DD');

            $scope.report.jobNos = $scope.selectedJobs.map(function(a){
                return  a.JOB_NO;
            });
            $scope.report.selectedJobTypeIDs = $scope.selectedJobTypeIDs.filter(Boolean);
            $scope.report.selectedRateGroupIDs = $scope.selectedRateGroupIDs.filter(Boolean);

            $scope.report.downloadReport = false;

            $scope.report.checkBigReport = true;

            $scope.report.downloadType = '';

            if($scope.hideProfit) {
                $scope.report.hideProfit = true;
            } else {
                $scope.report.hideProfit = false;
            }

            $rootScope.showLoader(true);
            commonFactory.post('/api/job_profit_report', $scope.report)
                .success(function(response) {
                    $scope.jobs = [];
                    $rootScope.showLoader(false);
                    if(response.error){
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else {
                        if(response.result.is_big_report) {
                            var btn = "button";
                            swal({
                                title: "Report Will Take Some Time For Selected Criteria. <br>Job Count: "+response.result.job_count,
                                text:'<button type="' + btn + '" id="btnBackExcel" >Email Excel</button> ' +
                                '<button type="' + btn + '" id="btnBackPDF" >Email PDF</button> '+
                                '<button type="' + btn + '" id="btnRun" >Continue Anyway<br><span style="color:red;font-size: 12px;font-weight: bold;">(Not Recommended)</span></button>',
                                html: true,
                                type: "warning",
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: "Cancel",
                                closeOnCancel: true
                            });
                             
                            $(document).off('click', "#btnRun");
                            $(document).on('click', "#btnRun", function() {
                                $scope.report.checkBigReport = false;
                                $rootScope.showLoader(true);
                                commonFactory.post('/api/job_profit_report', $scope.report)
                                    .success(function(response) {
                                        $rootScope.showLoader(false);
                                        if (response.status == 200) {
                                            $rootScope.showLoader(true);
                                            $scope.calculatedData = response.result.calculatedData;
                                            $scope.calculatedData.InvAmt_calculated = 0;
                                            $scope.jobs =  response.result.jobs;
                                            $rootScope.showLoader(false);

                                            if($scope.jobs.length == 0){
                                                Notification.error('No records found!');
                                            }
                                        } else {
                                            Notification.error(response.result.message);
                                        }
                                })
                                .error(function(err)
                                {
                                    Notification.error('Something went wrong!');
                                    $rootScope.showLoader(false);
                                });
                            });
                              
                            $(document).off('click', "#btnBackExcel");
                            $(document).on('click', "#btnBackExcel", function() {
                                $scope.report.downloadType = 'excel';
                                $http.post(apiUrl + '/api/job-profit-report-background', $scope.report).then(function(response) {
                                    if(response.data.error) {
                                        Notification.error(response.data.result.message || "Something went wrong!");
                                    } else {
                                        Notification.success(response.data.result.message);
                                    }
                                });
                            });
                            
                            $(document).off('click', "#btnBackPDF");
                            $(document).on('click', "#btnBackPDF", function() {
                                $scope.report.downloadType = 'pdf';
                                $http.post(apiUrl + '/api/job-profit-report-background', $scope.report).then(function(response) {
                                    if(response.data.error) {
                                        Notification.error(response.data.result.message || "Something went wrong!");
                                    } else {
                                        Notification.success(response.data.result.message);
                                    }
                                });
                            });
                        } else {
                            $rootScope.showLoader(true);
                            $scope.calculatedData = response.result.calculatedData;
                            $scope.calculatedData.InvAmt_calculated = 0;
                            $scope.jobs =  response.result.jobs;
                            $rootScope.showLoader(false);

                            if($scope.jobs.length == 0){
                                Notification.error('No records found!');
                            }
                        }
                    }
                })
                .error(function(err)
                {
                    $scope.jobs = [];
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };

        $scope.exportData = function(download=false, type='') {
            
            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
            $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $scope.report.attorneyFirmIds = $scope.selectedFirms.map(function(a){
                return  a.id;
            });

            $scope.report.isSalesPersonLogin = $rootScope.isSalesLogin;

            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
            $scope.report.endDate= moment($scope.report.endDate).format('YYYY-MM-DD');

            $scope.report.jobNos = $scope.selectedJobs.map(function(a){
                return  a.JOB_NO;
            });
            $scope.report.selectedJobTypeIDs = $scope.selectedJobTypeIDs.filter(Boolean);
            $scope.report.selectedRateGroupIDs = $scope.selectedRateGroupIDs.filter(Boolean);

            $scope.report.downloadReport = true;

            $scope.report.downloadType = type;

            $scope.report.checkBigReport = true;

            if($scope.hideProfit) {
                $scope.report.hideProfit = true;
            } else {
                $scope.report.hideProfit = false;
            }

            $rootScope.showLoader(true);

            commonFactory.post('/api/job_profit_report', $scope.report)
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200) {
                        if(response.result.is_big_report) {
                            var btn = "button";
                            swal({
                                title: "Report Will Take Some Time For Selected Criteria. <br>Job Count: "+response.result.job_count,
                                text:'<button type="' + btn + '" id="btnBackExcel" >Email Excel</button> ' +
                                '<button type="' + btn + '" id="btnBackPDF" >Email PDF</button> '+
                                '<button type="' + btn + '" id="btnRun" >Continue Anyway<br><span style="color:red;font-size: 12px;font-weight: bold;">(Not Recommended)</span></button>',
                                html: true,
                                type: "warning",
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: "Cancel",
                                closeOnCancel: true
                            });
                             
                            $(document).off('click', "#btnRun");
                            $(document).on('click', "#btnRun", function() {
                                $scope.report.checkBigReport = false;
                                $rootScope.showLoader(true);
                                commonFactory.post('/api/job_profit_report', $scope.report)
                                    .success(function(response) {
                                        $rootScope.showLoader(false);
                                        if (response.status == 200) {
                                            if (download && response.result.fileName) {
                                                $window.open(apiUrl +'/api/download-job-report?fileName='+response.result.fileName);
                                            } else {
                                                Notification.error('No records found!');
                                            }
                                        } else {
                                        Notification.error(response.result.message);
                                    }
                                })
                                .error(function(err)
                                {
                                    Notification.error('Something went wrong!');
                                    $rootScope.showLoader(false);
                                });
                            });
                              
                            $(document).off('click', "#btnBackExcel");
                            $(document).on('click', "#btnBackExcel", function() {
                                $scope.report.downloadType = 'excel';
                                $http.post(apiUrl + '/api/job-profit-report-background', $scope.report).then(function(response) {
                                    if(response.data.error) {
                                        Notification.error(response.data.result.message || "Something went wrong!");
                                    } else {
                                        Notification.success(response.data.result.message);
                                    }
                                });
                            });
                            
                            $(document).off('click', "#btnBackPDF");
                            $(document).on('click', "#btnBackPDF", function() {
                                $scope.report.downloadType = 'pdf';
                                $http.post(apiUrl + '/api/job-profit-report-background', $scope.report).then(function(response) {
                                    if(response.data.error) {
                                        Notification.error(response.data.result.message || "Something went wrong!");
                                    } else {
                                        Notification.success(response.data.result.message);
                                    }
                                });
                            });
                        } else {
                            if (download && response.result.fileName) {
                                $window.open(apiUrl +'/api/download-job-report?fileName='+response.result.fileName);
                            } else {
                                Notification.error('No records found!');
                            }
                        }
                    } else {
                        Notification.error(response.result.message);
                    }
                })
                .error(function(err)
                {
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };

        $scope.getReportingSalesmanNames = function(salespersons){
            var name = [];
            var i = 0;
            angular.forEach(salespersons,function(value, key){
                if(value.reptype && value.reptype.name){
                    name[i] = value.reptype.name;
                    i++;
                }
            });
            return name.join(', ');
        };
        $scope.increaseInvAmt = function(amt){
            $scope.calculatedData.InvAmt_calculated = Number($scope.calculatedData.InvAmt_calculated) + Number(amt);
            $scope.calculatedData.calculatedProfit = Number($scope.calculatedData.InvAmt_calculated) * Number($scope.calculatedData.Job_Profit_Pct) / 100;
            $scope.calculatedData.calculatedCost = Number($scope.calculatedData.InvAmt_calculated) - Number($scope.calculatedData.calculatedProfit);
        };

        if($state.params.attId){
            commonFactory.get('/api/attorneys/' + $state.params.attId + '/edit').success(function(response){
                var firmObj = response.result.data;
                $scope.selectedFirms.push({
                    id : firmObj.id,
                    NAME :firmObj.NAME,
                    firmNameWithAddress:firmObj.firmNameWithAddress
                });
                $scope.searchData();
            });
        }

        if($state.params.billerId){
            $scope.report.billerIds = [parseInt($state.params.billerId)];
            $scope.report.startDate = moment().startOf('month').format('YYYY-MM-DD');
            $scope.report.endDate = moment().endOf('month').format('YYYY-MM-DD');
            $scope.searchData();  
        }
}]);
