angular.module('deitz')
    .controller('sendJobResourceController', [
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'apiUrl',
        '$timeout',
        '$uibModal',
        'commonFactory',
        '$uibModalInstance',
        'Notification',
        '$compile',
        'codeFactory',
        'jobNo',
        function (
            $scope,
            $rootScope,
            $state,
            $http,
            apiUrl,
            $timeout,
            $uibModal,
            commonFactory,
            $uibModalInstance,
            Notification,
            $compile,
            codeFactory,
            jobNo) {


            $scope.title = "Send Job"
            $scope.resourceType = { 'R': 'Reporter', 'T': 'Typist', 'SC': 'Scopist', 'S': 'Account Executive', 'V': 'Videographer' };
            $scope.resources = [];
            $scope.formData = {};
            $scope.selectedResource = null;
            $scope.showMail = false;
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;



            $scope.getJobResourceList = function () {
                $rootScope.showLoader(true);
                commonFactory.get('/api/getJobResources/' + jobNo + '?token=' + true)
                    .success(function (response) {
                        if (response.success) {
                             $scope.job_resources=response.job_resources;
                             $scope.assignedResouces={};

                             $rootScope.showLoader(false);
                             angular.forEach(response.job_resources, function(value, key) {
                                var resource ={};
                                if(value.resource.REP_TYP=='R'){
                                    value.resource.additional_resource_type=value.additional_resource_type;
                                    value.resource.resource_type_id=value.resource_type_id;
                                    $scope.assignedResouces[value.resource.id +'_'+value.resource_type_id]=value.resource;
                                    if(value.typist && !$scope.assignedResouces[value.typist.id]){
                                        $scope.assignedResouces[value.typist.id]=value.typist;
                                    }
                                }
                                else{
                                    if(!$scope.assignedResouces[value.resource.id]){
                                        $scope.assignedResouces[value.resource.id]=value.resource;
                                    }
                                }
                             });
                            if(response.job_resources.length==1){
                                $scope.jobSmsForm.resource_id=Object.keys($scope.assignedResouces)[0];
                                $scope.changeResource();
                            }
                        }
                        else {
                            Notification.error('Something went wrong!');
                        }
                    })
                    .error(function (err) {
                        Notification.error(err.message || 'Something went wrong!');
                        $rootScope.showLoader(false);
                    });
            }
            $scope.getJobResourceList();
            $scope.cancel = function (isChanged) {
                $uibModalInstance.dismiss({ isChanged: isChanged });
            };

            $scope.changeResource = function () {
                // find selected resource
                $scope.formData={};
                $scope.formData.JOB_NO = jobNo;
                $scope.selectedResource = $scope.assignedResouces[$scope.jobSmsForm.resource_id];
                token = $scope.selectedResource.pendingjob_status.token;
    
                var textMsg = 'Depositionnet assignment - confirm or decline at:';
                var status = $scope.selectedResource.pendingjob_status.status ? $scope.selectedResource.pendingjob_status.status.toLowerCase() : '';
                switch (status) {
                    case 'sent':
                        textMsg = 'Depositionnet assignment - you have not replied:';
                        break;

                    case 'accepted':
                        textMsg = 'Depositionnet confirmed. Changes:';
                        break;

                    case 'declined':
                        textMsg = 'Depositionnet declined. Changes:';
                        break;
                    default:
                        break;
                }
                
                $scope.disableSend=false;
                if($scope.selectedResource.resource_communications.send_job_confirmation_sms=='N' &&
                $scope.selectedResource.resource_communications.send_job_confirmation_email=='N'
                ){
                    $scope.disableSend=true;
                }
                $scope.formData.email_address = $scope.selectedResource.Emai_Address;
                $scope.formData.CELL = $scope.selectedResource.CELL;
                $scope.formData.send_job_confirmation_sms = $scope.selectedResource.resource_communications.send_job_confirmation_sms;
                $scope.formData.send_job_confirmation_email = $scope.selectedResource.resource_communications.send_job_confirmation_email;
                if ($scope.selectedResource.REP_TYP=='T' || $scope.selectedResource.additional_resource_type.is_typist=='1') {
                    $scope.formData.message= textMsg + " \n" + $rootScope.depoHost + "/typist_confirmation.php?job_no=" + btoa(jobNo) + '&token=' + token + '&session=' + btoa($scope.selectedResource.id);
                    $scope.formData.URL = $rootScope.depoHost + "/typist_confirmation.php?job_no=" + btoa(jobNo) + '&token=' + token + '&session=' + btoa($scope.selectedResource.id),
                    $scope.showMail = true;
                }
                else{
                    $scope.formData.message = textMsg + " \n" + $rootScope.depoHost + "/job_confirmation.php?job_no=" + btoa(jobNo) + '&token=' + token + '&session=' + btoa($scope.selectedResource.id)+'&resource_type='+btoa($scope.selectedResource.resource_type_id),
                    $scope.formData.URL = $rootScope.depoHost + "/job_confirmation.php?job_no=" + btoa(jobNo) + '&token=' + token + '&session=' + btoa($scope.selectedResource.id)+'&resource_type='+btoa($scope.selectedResource.resource_type_id),
                    $scope.showMail = false;
                }
                $scope.formData.status = status;
                $scope.formData.resource_type = $scope.selectedResource.REP_TYP;
                $scope.formData.NAME_KEY = $scope.selectedResource.NAME_KEY;
                $scope.formData.resource_id = $scope.selectedResource.id,
                $scope.formData.token = token
                if($scope.formData.resource_type=='R'){
                    $scope.formData.resource_type_id =$scope.selectedResource.resource_type_id;
                }
            }

            $scope.sendJobConfirmation = function (isValid) {
                if($scope.selectedResource.resource_communications.send_job_confirmation_sms=='N' &&
                $scope.selectedResource.resource_communications.send_job_confirmation_email=='N'
                ){
                    Notification.error("No Communication Method Is Set For This Resource");
                    return false;
                }
                else if($scope.selectedResource.resource_communications.send_job_confirmation_sms=='Y' &&
                    $scope.selectedResource.resource_communications.send_job_confirmation_email=='N' &&
                    !$scope.formData.CELL)
                {
                    Notification.error("Please Enter Cell Phone");
                    return false;
                }
                else if($scope.selectedResource.resource_communications.send_job_confirmation_sms=='N' &&
                $scope.selectedResource.resource_communications.send_job_confirmation_email=='Y' &&
                !$scope.formData.email_address){
                    Notification.error("Please Enter Email");
                    return false;
                }
                else if(!$scope.formData.CELL && !$scope.formData.email_address){
                    Notification.error("Please Enter Email or Cell Phone");
                    return false;
                }
                
                if(isValid){
                $rootScope.showLoader(true);
                commonFactory.post('/api/sendJobResource', $scope.formData)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response.error) {
                            Notification.error(response.result.message || "Something went wrong while sending SMS!");
                        } else {
                            $uibModalInstance.dismiss({ isChanged: true });
                            Notification.success(response.result.message || "SMS successfully sent.");
                        }
                    }).error(function (error) {
                        $rootScope.showLoader(false);
                        Notification.error("Something went wrong while sending SMS!");

                    });
                }
            }

        }]);

